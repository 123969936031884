/**
 * General validation for an input
 * @param    {Object}   rules
 * 
 * Default:
 * @attribute required: true
 * @attribute name
 * 
 * Acepta:
 * @attribute    {Boolean}   required    Specifies if the field is required.
 * @attribute    {Number}    minLength      Specifies the minimum characters for the field.
 * @attribute    {Number}    maxLength      Specifies the maximum characters for the field.
 * @attribute    {String}    [name]        Specifies the field name.
 * 
 * @return   {Array<Fn>}             Array with the rules
 */

export const validations = {
  // Validación para cualquier campo requerido (MASCULINO).
  requiredMValidation(name = "campo") {
    return [(v) => !!v || `El ${name} es obligatorio.`];
  },
  // Validación para cualquier campo requerido (FEMENINO).
  requiredFValidation(name = "información") {
    return [(v) => !!v || `La ${name} es obligatoria.`];
  },
  comboValidation(name = 'campos') {
    return [
      v => v.length > 0 || `Los ${name} son obligatorios.`
    ]
  },
  // Validación general, funciona para validar casi cualquier campo (MASCULINO).
  generalMValidation(rules = { name: "campo", required: true }) {
    if (!rules.name) rules.name = "campo";
    let validations = [];

    if (rules.required)
      validations.push((v) => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && rules.minLength != 0 && rules.required)
      validations.push(
        (v) =>
          v && v.length >= rules.minLength ||
          `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`
      );
    else if (rules.minLength && rules.minLength != 0 && !rules.required)
      validations.push(
        (v) =>
          v && v.length && v.length === 0 ||
          v.length >= rules.minLength ||
          `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`
      );

    if (rules.maxLength)
      validations.push(
        (v) =>
          v && v.length && v.length === 0 ||
          v.length <= rules.maxLength ||
          `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`
      );

    //validations.push(v => !v || /^[^"]*$/.test(v) || `El ${rules.name} no puede incluir el siguiente simbolo " .`)
    return validations;
  },
  // Validación general funciona para validar casi cualquier campo (FEMENINO).
  generalFValidation(rules = { name: "información", required: true }) {
    if (!rules.name) rules.name = "información";
    let validations = [];

    if (rules.required)
      validations.push((v) => !!v || `La ${rules.name} es obligatoria.`);

    if (rules.minLength && rules.minLength != 0 && rules.required)
      validations.push(
        (v) =>
          v && v.length >= rules.minLength ||
          `La ${rules.name} debe tener al menos ${rules.minLength} caracteres.`
      );
    else if (rules.minLength && rules.minLength != 0 && !rules.required)
      validations.push(
        (v) =>
          v && v.length && v.length === 0 ||
          v.length >= rules.minLength ||
          `La ${rules.name} debe tener al menos ${rules.minLength} caracteres.`
      );

    if (rules.maxLength)
      validations.push(
        (v) =>
          v && v.length && v.length === 0 ||
          v.length <= rules.maxLength ||
          `La ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`
      );

    return validations;
  },
  // Validación general, funciona para validar casi cualquier campo en plural (MASCULINO).
  generalPluralMValidation(rules = { name: "campo", required: true }) {
    if (!rules.name) rules.name = "campo";
    let validations = [];

    if (rules.required)
      validations.push((v) => !!v || `Los ${rules.name} son obligatorios.`);

    validations.push(
      (v) =>
        !v ||
        /^[^"]*$/.test(v) ||
        `El ${rules.name} no puede incluir el siguiente simbolo " .`
    );
    return validations;
  },
  nameValidation(rules = { name: "nombre", required: true, minLength: 2 }) {

    if (!rules.name) rules.name = "nombre";
    let validations = [];

    if (rules.required)
      validations.push(v => v !== '' || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    // validations.push(v => !v || /^[a-zA-ZÁÉÍÓÚÑáéíóúñ ]*$/.test(v) || `El ${rules.name} no debe incluir números ni símbolos.`)

    return validations;
  },
  companyValidation(rules = { name: "nombre", required: false, minLength: 2 }) {
    if (!rules.name) rules.name = "nombre";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    // validations.push(v => !v || /^[0-9a-zA-ZÁÉÍÓÚÑáéíóúñ ]*$/.test(v) || `El ${rules.name} no debe incluir símbolos.`)

    return validations;
  },
  extraFieldValidation(rules = { name: "nombre del campo", required: false, minLength: 2 }) {
    rules.maxLength = 40;
    if (!rules.name) rules.name = "nombre del campo";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    validations.push(v => !v || /^[a-zA-Z0-9ÁÉÍÓÚÑáéíóúñ¿?. ]*$/.test(v) || `El ${rules.name} solo puede incluir los simbolos ¿ y ?.`)

    return validations;
  },
  questionFieldValidation(rules = { name: "nombre del campo", required: false, minLength: 2 }) {
    //rules.maxLength = 20;
    if (!rules.name) rules.name = "nombre del campo";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    validations.push(v => !v || /^[a-zA-Z0-9ÁÉÍÓÚÑáéíóúñ¿?.:; ]*$/.test(v) || `El ${rules.name} solo puede incluir los simbolos ¿ y ?.`)

    return validations;
  },
  answerFieldValidation(rules = { name: "nombre del campo", required: false, minLength: 2 }) {
    rules.maxLength = 1000;
    if (!rules.name) rules.name = "nombre del campo";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    validations.push(v => !v || /^[a-zA-Z0-9ÁÉÍÓÚÑáéíóúñ¿?'.*+-:; ]*$/.test(v) || `El ${rules.name} solo puede incluir los simbolos ¿?'.*+-:;.`)

    return validations;
  },
  surnamesValidation(rules = { name: "apellidos", required: true, minLength: 2 }) {
    if (!rules.name) rules.name = "apellidos";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `Los ${rules.name} son obligatorios.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `Los ${rules.name} deben tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `Los ${rules.name} deben tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `Los ${rules.name} deben tener como máximo ${rules.maxLength} caracteres.`);

    // validations.push(v => !v || /^[a-zA-ZÁÉÍÓÚÑáéíóúñ ]*$/.test(v) || `Los ${rules.name} no deben incluir números ni símbolos.`)

    return validations;
  },
  emailValidation(rules = { name: "email", required: true }) {
    if (!rules.name) rules.name = "email";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    validations.push(v => !v || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4}$|properties$|royalty$|services$)/.test(v) || `Debe introducir un ${rules.name} válido.`)

    return validations;
  },
  // Validación del teléfono.
  phoneValidation(rules = { name: "teléfono", required: false }) {
    if (!rules.name) rules.name = "teléfono";
    let validations = [];

    validations.push(v => !v || /^[0-9]*$/.test(v) || `El ${rules.name} solo debe incluir números.`);
    if (rules.required) {
      validations.push((v) => !!v || `El ${rules.name} es obligatorio.`);
      validations.push(
        (v) =>
          String(v).length > 9 ||
          `El ${rules.name} debe tener por lo menos 10 dígitos.`
      );
      validations.push(
        (v) =>
          String(v).length < 11 ||
          `El ${rules.name} debe tener máximo 10 dígitos.`
      );
    } else
      validations.push(
        (v) =>
          String(v).length === 0 ||
          String(v).length > 9 ||
          `El ${rules.name} debe tener por lo menos 10 dígitos.`
      );
    validations.push(
      (v) =>
        String(v).length < 11 ||
        `El ${rules.name} debe tener máximo 10 dígitos.`
    );
    return validations;
  },
  phoneValidationEvent(rules = { name: "teléfono", required: false }) {
    if (!rules.name) rules.name = "teléfono";
    let validations = [];

    if (rules.required) {
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);
      validations.push(v => (String(v).length > 9 && String(v).length < 14) || `El ${rules.name} debe tener entre 10 y 12 dígitos.`);
    } else
      validations.push(v => String(v).length === 0 || (String(v).length > 9 && String(v).length < 14) || `El ${rules.name} debe tener entre 10 y 12 dígitos.`);
    return validations;
  },
  zipCodeValidation(rules = { name: "código postal", minLength: 5, maxLength: 5, required: true }) {
    if (!rules.name) rules.name = "código postal";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => String(v).length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => String(v).length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => String(v).length === 0 || String(v).length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    validations.push(v => !v || /^[0-9]*$/.test(v) || `El ${rules.name} solo debe incluir números.`);

    return validations;
  },
  websiteValidation(rules = { name: "link", required: true }) {
    if (!rules.name) rules.name = "link";
    let validations = [];

    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    validations.push(v => !v || /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(v) || 'Debe incluir una URL válida.')

    return validations;
  },
  /**
   * Validación para un campo numérico (MASCULINO).
   *
   * @param {Object} rules
   * @param {String} rules.name
   * @param {Boolean} rules.required
   * @param {Number} rules.min
   * @param {Number} rules.max
   *
   * @returns {Array}
   */
  numberMValidation(rules = { name: "campo", required: true }) {
    if (!rules.name) rules.name = "campo";
    let validations = [];

    if (rules.required)
      validations.push((v) => !!v || `El ${rules.name} es obligatorio.`);

    validations.push((v)=> v > 0 || `El ${rules.name} debe ser un valor positivo`);

    if (rules.min && rules.required)
      validations.push(
        (v) => Number(v) >= rules.min || `El valor mínimo es ${rules.min}.`
      );
    else if (rules.min && !rules.required)
      validations.push(
        (v) =>
          v?.length === 0 ||
          Number(v) >= rules.min ||
          `El valor mínimo es ${rules.min}.`
      );

    if (rules.max)
      validations.push(
        (v) =>
          v?.length === 0 ||
          Number(v) <= rules.max ||
          `El valor máximo es ${rules.max}.`
      );

    return validations;
  },

  // Validación para un campo numérico (FEMENINO).
  numberFValidation(rules = { name: "cantidad", required: true }) {
    if (!rules.name) rules.name = "cantidad";
    let validations = [];
    
    validations.push((v) => !!v || !(/^[0-9]*$/.test(v)) || `La ${rules.name} solo debe contener numeros.`) 

    validations.push((v)=> v > 0 || `La ${rules.name} debe ser un valor positivo`);

    if (rules.required)
      validations.push((v) => !!v || `La ${rules.name} es obligatoria.`);

    if (rules.min && rules.min != 0 && rules.required)
      validations.push(
        (v) => Number(v) >= rules.min || `El valor mínimo es ${rules.min}.`
      );
    else if (rules.min && rules.min != 0 && !rules.required)
      validations.push(
        (v) =>
          v.length === 0 ||
          Number(v) >= rules.min ||
          `El valor mínimo es ${rules.min}.`
      );

    if (rules.max)
      validations.push(
        (v) =>
          v.length === 0 ||
          Number(v) <= rules.max ||
          `El valor máximo es ${rules.max}.`
      );

    return validations;
  },
  rfcValidation(rules = { name: "RFC", minLength: 12, maxLength: 13, required: true }) {
    if (!rules.name) rules.name = "RFC";
    let validations = [];

    /*
    function rfc(v) {
        const aceptarGenerico = true
        const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        var validado = v.match(re);

        if (!validado) //Coincide con el formato general del regex?
            return false;

        //Separar el dígito verificador del resto del RFC
        const digitoVerificador = validado.pop(),
            rfcSinDigito = validado.slice(1).join(''),
            len = rfcSinDigito.length,

            //Obtener el digito esperado
            diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
            indice = len + 1;
        var suma,
            digitoEsperado;

        if (len == 12) suma = 0
        else suma = 481; //Ajuste para persona moral

        for (var i = 0; i < len; i++)
            suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
        digitoEsperado = 11 - suma % 11;
        if (digitoEsperado == 11) digitoEsperado = 0;
        else if (digitoEsperado == 10) digitoEsperado = "A";

        //El dígito verificador coincide con el esperado?
        // o es un RFC Genérico (ventas a público general)?
        if ((digitoVerificador != digitoEsperado) &&
            (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
            return false;
        else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
            return false;
        return true;
    }*/
    if (rules.required)
      validations.push(v => !!v || `El ${rules.name} es obligatorio.`);

    validations.push(v => !v || /^[0-9A-ZÁÉÍÓÚÑ&]*$/.test(v) || `El ${rules.name} debe de estar en mayúsculas y no debe incluir símbolos ni espacios.`)

    if (rules.minLength && (rules.minLength != 0 && rules.required))
      validations.push(v => v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    else if (rules.minLength && (rules.minLength != 0 && !rules.required))
      validations.push(v => v.length === 0 || v.length >= rules.minLength || `El ${rules.name} debe tener al menos ${rules.minLength} caracteres.`);

    if (rules.maxLength)
      validations.push(v => v.length === 0 || v.length <= rules.maxLength || `El ${rules.name} debe tener como máximo ${rules.maxLength} caracteres.`);

    //validations.push(v => rfc(v) == true || `El ${rules.name} es inválido.`);

    return validations;
  },
  fileValidation(rule = { value: 2000000 }) {
    let validations = [];
    validations.push(v => !!v || "El documento es obligatorio.")
    validations.push(v => !v || v.size <= rule.value || "El tamaño del documento debe ser menor a 2MB")
    validations.push(v => !v || v.type === 'application/pdf' || 'El formato debe ser .pdf');
    return validations;
  },
  imageFileValidation(rule = { value: 2000000 }) {
    let validations = [];
    validations.push(v => !!v || "El documento es obligatorio.")
    validations.push(v => !v || v.size <= rule.value || "El tamaño del documento debe ser menor a 2MB")
    validations.push(v => !v || v.type === 'image/jpg' || v.type === 'image/png' || v.type === 'image/jpeg' || 'El formato debe ser .png, .jpg o .jpeg');
    return validations;
  },
  password(options = { name: 'contraseña', required: true }) {
    return this.generalFValidation({
      ...options,
      minLength: 8
    });
  },
  newPasswordRule(){
    return [
      (v) => !!v || "La nueva contraseña es requerida",
      (v) => v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
      (v) => /[A-Z]/.test(v) || "La contraseña debe tener al menos una letra mayúscula",
      (v) => /[a-z]/.test(v) || "La contraseña debe tener al menos una letra minúscula",
      (v) => /[^A-Za-z0-9]/.test(v) || "La contraseña debe tener al menos un caracter especial",
    ]
  },
  couponCodeRule(){
    return [
      (v) => !!v || "El código de cupón es requerido",
      (v) => !/\s/.test(v) || "El código de cupón no puede contener espacios",
    ]
  }
}