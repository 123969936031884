/**
 * Mutación para guardar el estado del usuario que ha iniciado sesión
 * @param { object } state Estado del módulo actual
 * @param { object } user Datos del usuario que ha iniciado sesión
 */
export function setUser(state, user) {
  state.user = user;
  state.loggedIn = true;
}

/**
 * Función para agregar el token
 * @param {object} state El estado del módulo actual.
 */
 export function setToken(state, token) {
  state.token = token
}

/**
 * Función para cerrar la sesión, eliminar los datos del estado y borrar los datos del Local storage.
 * @param {object} state El estado del módulo actual.
 */
 export function logout(state) {
  state.user = {}
  state.loggedIn = false;
  state.token = ''
  localStorage.removeItem('USER_TOKEN');
}

export function setIndicators(state, indicators) {
  state.indicators = indicators;
}

export function setUserProfilePicture(state, imageUrl = '') {
  state.user.profilePicture = imageUrl;
}