export const IMEF_GROUPS = [
  {
    account: '1130-01-00-00-00',
    name: 'QuintanaRoo',
    segment: 49,
    Serie: 'QROO',
    zipCode: '03920',
  },
  {
    account: '1130-02-00-00-00',
    name: 'Chihuahua',
    segment: 22,
    Serie: 'CHI',
    zipCode: '03920',
  },
  {
    account: '1130-03-00-00-00',
    name: 'Cd Juárez',
    segment: 21,
    Serie: 'CDJ',
    zipCode: '03920',
  },
  {
    account: '1130-04-00-00-00',
    name: 'Cd de México',
    segment: 32,
    Serie: 'MX',
    zipCode: '03920',
  },
  {
    account: '1130-05-00-00-00',
    name: 'Coahuila',
    segment: 56,
    Serie: 'COA',
    zipCode: '03920',
  },
  {
    account: '1130-07-00-00-00',
    name: 'Guadalajara',
    segment: 52,
    Serie: 'GDL',
    zipCode: 45040,
  },
  {
    account: '1130-08-00-00-00',
    name: 'Guanajuato',
    segment: 41,
    Serie: 'GTO',
    zipCode: '03920',
  },
  {
    account: '1130-09-00-00-00',
    name: 'La Laguna',
    segment: 53,
    Serie: 'LAG',
    zipCode: '03920',
  },
  {
    account: '1130-10-00-00-00',
    name: 'Baja Califoria Sur (Los Cabos)',
    segment: 58,
    Serie: 'CAB',
    zipCode: '03920',
  },
  {
    account: '1130-11-00-00-00',
    name: 'Monterrey',
    segment: 42,
    Serie: 'MTY',
    zipCode: 66269,
  },
  {
    account: '1130-12-00-00-00',
    name: 'Morelos',
    segment: 33,
    Serie: 'MOR',
    zipCode: '03920',
  },
  {
    account: '1130-13-00-00-00',
    name: 'Puebla',
    segment: 34,
    Serie: 'PUE',
    zipCode: '03920',
  },
  {
    account: '1130-14-00-00-00',
    name: 'Querétaro',
    segment: 35,
    Serie: 'QRO',
    zipCode: '03920',
  },
  {
    account: '1130-15-00-00-00',
    name: 'San Luis Potosí',
    segment: 59,
    Serie: 'SLP',
    zipCode: '03920',
  },
  {
    account: '1130-16-00-00-00',
    name: 'Sonora',
    segment: 60,
    Serie: 'SON',
    zipCode: '03920',
  },
  {
    account: '1130-17-00-00-00',
    name: 'Tampico',
    segment: 43,
    Serie: 'TAM',
    zipCode: '03920',
  },
  {
    account: '1130-18-00-00-00',
    name: 'Estado de México (Toluca)',
    segment: 36,
    Serie: 'TOL',
    zipCode: 45040,
  },
  {
    account: '1130-19-00-00-00',
    name: 'Veracruz',
    segment: 45,
    Serie: 'VER',
    zipCode: '03920',
  },
  {
    account: '1130-20-00-00-00',
    name: 'Yucatán',
    segment: 46,
    Serie: 'YUC',
    zipCode: '03920',
  },
  {
    account: '1130-21-00-00-00',
    name: 'IMEF Universitario',
    segment: 65,
    Serie: 'UNI',
    zipCode: '03920',
  },
  {
    account: '1130-22-00-00-00',
    name: 'Fundación',
    segment: 63,
    Serie: 'FUN',
    zipCode: '03920',
  },
  {
    account: '1130-23-00-00-00',
    name: 'Editorial (Revista)',
    segment: 61,
    Serie: 'REV',
    zipCode: '03920',
  },
  {
    account: '1130-24-00-00-00',
    name: 'Oficina Central',
    segment: 1,
    Serie: 'NAL',
    zipCode: '03920',
  },
  {
    account: '1130-27-00-00-00',
    name: 'Convención Nacional',
    segment: 2,
    Serie: 'CONV',
    zipCode: '03920',
  },
  {
    account: '1130-28-00-00-00',
    name: 'Congreso de Investigación Financiera',
    segment: 64,
    Serie: 'CONG',
    zipCode: '03920',
  },
  {
    account: '1130-30-00-00-00',
    name: 'Sinaloa',
    segment: 55,
    Serie: 'SIN',
    zipCode: '03920',
  },
];

export const IMEF_GROUPS_CODES = {
  "CIUDAD_DE_MEXICO": {
    "segmento": 32,
    "code": "CDM"
  },
  "MONTERREY": {
    "segmento": 42,
    "code": "MTY"
  },
  "ESTADO_DE_MEXICO": {
    "segmento": 36,
    "code": "TOL"
  },
  "LA_LAGUNA": {
    "segmento": 53,
    "code": "LAG"
  },
  "GUADALAJARA": {
    "segmento": 52,
    "code": "GDL"
  },
  "COAHUILA_SURESTE": {
    "segmento": 56,
    "code": "COA"
  },
  "MORELOS": {
    "segmento": 33,
    "code": "MOR"
  },
  "PUEBLA": {
    "segmento": 34,
    "code": "PUE"
  },
  "TAMPICO": {
    "segmento": 43,
    "code": "TAM"
  },
  "QUERETARO": {
    "segmento": 35,
    "code": "QRO"
  },
  "CHIAPAS": {
    "segmento": 62,
    "code": "CHIA"
  },
  "BAJA_CALIFORNIA_SUR": {
    "segmento": 58,
    "code": "BCS"
  },
  "CIUDAD_JUAREZ": {
    "segmento": 21,
    "code": "CDJ"
  },
  "CHIHUAHUA": {
    "segmento": 22,
    "code": "CHI"
  },
  "GUANAJUATO": {
    "segmento": 41,
    "code": "GTO"
  },
  "SAN_LUIS_POTOSI": {
    "segmento": 59,
    "code": "SLP"
  },
  "SINALOA": {
    "segmento": 55,
    "code": "SIN"
  },
  "SONORA": {
    "segmento": 60,
    "code": "SON"
  },
  "VERACRUZ": {
    "segmento": 45,
    "code": "VER"
  },
  "YUCATAN": {
    "segmento": 46,
    "code": "YUC"
  },
  "QUINTANA_ROO": {
    "segmento": 49,
    "code": "CAN"
  }
}

export const IMEF_SERVICES = [
  {
    serviceCode: 'ICAEE',
    name: 'Comite Asesor de Estudios Economicos',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 81121500,
    accountingSegment: 112004120300,
    accountingSegment2: 410004120300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICAEE-P',
    name: 'Patrocinio Comité asesor de estudios economicos',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 81121500,
    accountingSegment: 112005120300,
    accountingSegment2: 410005120300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICCL-A',
    name: 'Consejo Consultivo asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004010200,
    accountingSegment2: 410004010200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICCL-P',
    name: 'Consejo Consultivo patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112005010200,
    accountingSegment2: 410005010200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICCN-A',
    name: 'Consejo Consultivo Nacional asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004010100,
    accountingSegment2: 410004010100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICCN-P',
    name: 'Consejo Consultivo Nacional patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112005010100,
    accountingSegment2: 410005010100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICDL-A',
    name: 'Consejo Directivo Local asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004030200,
    accountingSegment2: 410004030200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICDL-P',
    name: 'Consejo Directivo Local patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112005030200,
    accountingSegment2: 410005030200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICDN-A',
    name: 'Consejo Directivo Nacional asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004030100,
    accountingSegment2: 410004030100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '1/9/2017',
  },
  {
    serviceCode: 'ICDN-P',
    name: 'Consejo Directivo Nacional Patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112005030100,
    accountingSegment2: 410005030100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICEC001',
    name: 'Comisión Ejecutiva de Certificación',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004130100,
    accountingSegment2: 410004130100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICEC002',
    name: 'Junta Extraordinaria',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004130200,
    accountingSegment2: 410004130200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICEC003',
    name: 'Diplomado',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004130300,
    accountingSegment2: 410004130300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICEN-A',
    name: 'Consejo Ejecutivo Nacional asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004020100,
    accountingSegment2: 410004020100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICEN-P',
    name: 'Consejo Ejecutivo Nacional patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112005020100,
    accountingSegment2: 410005020100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICFO-A',
    name: 'CFO asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004080400,
    accountingSegment2: 410004080400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICFO-P',
    name: 'CFO patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005080400,
    accountingSegment2: 410005080400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICNA',
    name: 'CONVENCION NACIONAL',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004040100,
    accountingSegment2: 220001040100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '11/22/2016',
  },
  {
    serviceCode: 'ICTL-A',
    name: 'Comité Técnico Local',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80101500,
    accountingSegment: 112004070200,
    accountingSegment2: 410004070200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICTL-P',
    name: 'Patrocinio Comité Técnico Local',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005070200,
    accountingSegment2: 410005070200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICTN-A',
    name: 'Comité Técnico Nacional asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004070100,
    accountingSegment2: 410004070100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'ICTN-P',
    name: 'Comité Técnico Local patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005070100,
    accountingSegment2: 410005070100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IEAS-A',
    name: 'Evento Asociado asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004140000,
    accountingSegment2: 410004140000,
    accountingSegment3: '',
    status: 1,
    terminationDate: '10/6/2017',
  },
  {
    serviceCode: 'IEAS-P',
    name: 'Evento Asociado patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005140000,
    accountingSegment2: 410004140000,
    accountingSegment3: '',
    status: 1,
    terminationDate: '10/6/2017',
  },
  {
    serviceCode: 'IEDEP-A',
    name: 'Evento Depostivo asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141607,
    accountingSegment: 112004080100,
    accountingSegment2: 410004080100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IEDEP-P',
    name: 'Patrocinio Evento Deportivo',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005080100,
    accountingSegment2: 410005080100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IESOC-A',
    name: 'Evento Social asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141607,
    accountingSegment: 112004080200,
    accountingSegment2: 410004080200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IESOC-P',
    name: 'Evento Social patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005080200,
    accountingSegment2: 410005080200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLCOM-A',
    name: 'Comida Técnica asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060600,
    accountingSegment2: 410004060600,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLCOM-P',
    name: 'Comida Técnica patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060600,
    accountingSegment2: 410005060600,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLCONF-A',
    name: 'Conferencia asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060100,
    accountingSegment2: 410004060100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLCONF-P',
    name: 'Conferencia patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060100,
    accountingSegment2: 410005060100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLCUR-A',
    name: 'Curso asistencoa',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060200,
    accountingSegment2: 410004060200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLCUR-P',
    name: 'Curso patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060200,
    accountingSegment2: 410005060200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLDES-A',
    name: 'Desayuno asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060500,
    accountingSegment2: 410004060500,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLDES-P',
    name: 'Desayuno patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060500,
    accountingSegment2: 410005060500,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLDIP-A',
    name: 'Diplomado asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060700,
    accountingSegment2: 410004060700,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLDIP-P',
    name: 'Diplomado patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060700,
    accountingSegment2: 410005060700,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLHB-A',
    name: 'Evento Hibrido asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060800,
    accountingSegment2: 410004060800,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLHB-P',
    name: 'Evento hibrido patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060800,
    accountingSegment2: 410005060800,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLSEM-A',
    name: 'Seminario asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060400,
    accountingSegment2: 410004060400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLSEM-P',
    name: 'Seminario patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060400,
    accountingSegment2: 410005060400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLTAL-A',
    name: 'Taller asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004060300,
    accountingSegment2: 410004060300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLTAL-P',
    name: 'Taller patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005060300,
    accountingSegment2: 410005060300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLWB-A',
    name: 'Webinar asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004150000,
    accountingSegment2: 410004150000,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETLWB-P',
    name: 'Webinar patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005150000,
    accountingSegment2: 410005150000,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNC-A',
    name: 'Congreso asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004050100,
    accountingSegment2: 410004050100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNC-P',
    name: 'Congreso patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005050100,
    accountingSegment2: 410005050100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNF-A',
    name: 'Foro asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004050300,
    accountingSegment2: 410004050300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '8/15/2016',
  },
  {
    serviceCode: 'IETNF-P',
    name: 'Foro patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005050300,
    accountingSegment2: 410005050300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '8/15/2016',
  },
  {
    serviceCode: 'IETNFM-A',
    name: 'Foro Mujer asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004050400,
    accountingSegment2: 410004050400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNFM-P',
    name: 'Foro Mujer patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005050400,
    accountingSegment2: 410005050400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNIND-A',
    name: 'Indicador Nacional - Asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004050500,
    accountingSegment2: 410004050500,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNIND-P',
    name: 'Indicador Nacional - Patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005050500,
    accountingSegment2: 410005050500,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNS-A',
    name: 'Simposium asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004050200,
    accountingSegment2: 410004050200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IETNS-P',
    name: 'Simposium patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005050200,
    accountingSegment2: 410005050200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFUCE',
    name: 'Comisión Ejecutiva',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004090200,
    accountingSegment2: 410004090200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFUCI',
    name: 'Comision de Investigación',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004090100,
    accountingSegment2: 410004090100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFUCINV-A',
    name: 'Congreso de Investigación Financiera asistencia',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004090400,
    accountingSegment2: 410004090400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFUCINV-P',
    name: 'Congreso de Investigación Financiera patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005090400,
    accountingSegment2: 410005090400,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFUPIF-P',
    name: 'Patrocinio Premio de Investigación',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005090700,
    accountingSegment2: 410005090800,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFURE',
    name: 'Suscripción  REMEF',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 82121800,
    accountingSegment: 112004090500,
    accountingSegment2: 410004090500,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IFURE-P',
    name: 'Patrocinio REMEF',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005090500,
    accountingSegment2: 410005090500,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IIND',
    name: 'Indicador',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 81121500,
    accountingSegment: 112004120200,
    accountingSegment2: 410004120200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IIND-P',
    name: 'Patrocinio Indicador',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 81121500,
    accountingSegment: 112005120200,
    accountingSegment2: 410005120200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IJA',
    name: 'Juntas y asambleas',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004120100,
    accountingSegment2: 410004120100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IJA-P',
    name: 'Patrocinio Juntas y asambleas',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112005120100,
    accountingSegment2: 410005120100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IPAT',
    name: 'Patrocinios',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005990000,
    accountingSegment2: 410005099000,
    accountingSegment3: '',
    status: 1,
    terminationDate: '3/10/2017',
  },
  {
    serviceCode: 'IREPU',
    name: 'Publicidad',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 82101503,
    accountingSegment: 112004110100,
    accountingSegment2: 410004110100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IRESU',
    name: 'Suscripción',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101600,
    accountingSegment: 112004110200,
    accountingSegment2: 410004110200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IREVR',
    name: 'Venta de Revista',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 55101524,
    accountingSegment: 112004110300,
    accountingSegment2: 410004110300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNC-A',
    name: 'Congreso Universitario',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101700,
    accountingSegment: 112004100100,
    accountingSegment2: 410004100100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNC-P',
    name: 'Patrocinio Congreso Universitario',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005100100,
    accountingSegment2: 410005100100,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNEN-A',
    name: 'Encuentro Nacional de Mesas IMEF Universitario',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101705,
    accountingSegment: 112004100300,
    accountingSegment2: 410004100300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNEN-P',
    name: 'Encuentro Nacional de Mesas IMEF Universitario patrocinio',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 86101705,
    accountingSegment: 112005100300,
    accountingSegment2: 410005100300,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNIME',
    name: 'MEMBRESIA',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101600,
    accountingSegment: 112001020000,
    accountingSegment2: 410001010301,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNJS-A',
    name: 'Junta semestral',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 94101610,
    accountingSegment: 112004100200,
    accountingSegment2: 410004100200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
  {
    serviceCode: 'IUNJS-P',
    name: 'Patrocinio Junta Semestral',
    price: 0,
    extraImport1: 0,
    price1: 0,
    sat: 80141609,
    accountingSegment: 112005100200,
    accountingSegment2: 410005100200,
    accountingSegment3: '',
    status: 1,
    terminationDate: '30/12/1899',
  },
];

export function formatGroupsItems(itemArray) {
  return [...itemArray].map((item) => {
    return { text: item.name, value: item };
  });
}

export function getAccountingSegments(service) {
  const { accountingSegment, accountingSegment2 } = service;
  return [accountingSegment, accountingSegment2];
}

export function formatServiceItems(itemArray) {
  return [...itemArray].map((item) => {
    return { text: `${item.serviceCode} - ${item.name}`, value: item };
  });
}

export function getImefGroupCode(segment) {
  let groupIndex = [...IMEF_GROUPS_CODES].findIndex((element) => element.segment === segment);
  return IMEF_GROUPS[groupIndex];
}

export function isImefOrganization(_id) {
  return ['66fb2d896646ca001cb93b3f', '6261f728520125001c7ad22f', '6724fba1633a0427c816d1d9', '67251067633a0427c816d9cb', '67254f2d633a0427c8175407', '675a1d75737d40001bceb674'].includes(_id)
}
